import PropTypes from "prop-types";
import React, { Fragment, useRef, useState } from "react";

const sendToSlack = async (webhookUrl, email) => {
  const payload = {
    text: `New email subscription: ${email}`,
  };

  try {
    const response = await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Failed to send email to Slack");
    }
    return { status: "success", message: "Email sent to Slack successfully" };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

const CustomForm = ({ webhookUrl }) => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const emailRef = useRef(null);

  const submit = async () => {
    const email = emailRef.current?.value;
    if (email && email.indexOf("@") > -1) {
      setStatus("sending");
      const result = await sendToSlack(webhookUrl, email);
      setStatus(result.status);
      setMessage(result.message);
      if (emailRef.current) {
        emailRef.current.value = ""; // Reset the input field safely
      }
    } else {
      setStatus("error");
      setMessage("Please enter a valid email address");
    }
  };

  return (
    <Fragment>
      <div className="mc-newsletter-form space-mb--10">
        <input
          ref={emailRef}
          type="email"
          placeholder="Enter email here"
          required
        />
        <button onClick={submit}>NOTIFY ME</button>
      </div>

      <div className="mailchimp-alerts">
        {status === "sending" && (
          <div className="mailchimp-submitting">sending...</div>
        )}
        {status === "error" && (
          <div className="mailchimp-error">{message}</div>
        )}
        {status === "success" && (
          <div className="mailchimp-success">{message}</div>
        )}
      </div>
    </Fragment>
  );
};

CustomForm.propTypes = {
  webhookUrl: PropTypes.string.isRequired,
};

const SubscribeEmail = ({ mailchimpUrl }) => {
  return (
    <div>
      <CustomForm webhookUrl={mailchimpUrl} />
    </div>
  );
};

SubscribeEmail.propTypes = {
  mailchimpUrl: PropTypes.string.isRequired,
};

export default SubscribeEmail;
