import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";
import SubscribeEmail from "../components/newsletter/SubscribeEmail";

const ComingSoon = () => {
  return (
    <Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&family=Roboto:wght@400;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div
        className="cs-11-page-wrapper h-100 bg-img d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            "/assets/img/backgrounds/tc-coming-soon.jpg"
          })`
        }}
      >
        {/*====================  header ====================*/}
        <header className="cs-11-header space-pt--30 space-pb--30" />
        {/*====================  End of header  ====================*/}
        {/*====================  content ====================*/}
        <div className="cs-11-content">
          <div className="container">
            <div className="row">
              <div className="col-6 ml-auto mr-auto">
                {/* logo */}
                <div className="cs-11-logo text-center space-mb--35">
                  
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 ml-auto mr-auto">
                <h2 className="cs-11-content__title text-center">
                  The Ultimate<br />SMS Platform
                </h2>
                <br></br>
                <div className="cs-11-subscription-wrapper space-mt--50 text-center">
                  {/* subscribe email */}
                  <SubscribeEmail mailchimpUrl="https://hooks.slack.com/services/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" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of content  ====================*/}
        {/*====================  footer ====================*/}
        <footer className="cs-11-footer space-pt--25 space-pb--25">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                {/* social icons */}
                <ul className="cs-11-social-icons d-flex align-items-center justify-content-center justify-content-md-start">
                  <li>
                    <a
                      href="http://www.discord.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/discord.svg"
                        }
                      />
                    </a>
                  </li>
                                
                </ul>
              </div>
              <div className="col-12 col-md-6">
                {/* copyright */}
                <div className="cs-11-copyright text-right">
                  &copy; {new Date().getFullYear() + " "}
                  <a
                    href="https://nine42.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Nine 42
                  </a>
                  , all rights reserved
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====================  End of footer  ====================*/}
      </div>
    </Fragment>
  );
};

export default ComingSoon;
